import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Http } from "../../api/Http"; // Assuming Http is your Axios instance
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";
import axios from "axios";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import Modal from "react-bootstrap/Modal";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const PayerCallBack = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  localStorage.setItem("Payer_Code", code);
  const error = urlParams.get("error");
  //const errorDescription = urlParams.get("error_description");
  const [show, setShow] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [payerInformation, setPayerInformation] = useState(null);
  const [isError, setIsError] = useState("");
  const [data, setData] = useState({
    tokenURL: "",
    client_id: "",
    clientSecret: "",
    redirect_uri: "",
    payerMemberID: "",
    refreshToken: "",
  });
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/PreviousInsurer");
  };

  const selectedPayer = sessionStorage.getItem("selected_payer");

  const fetchPayerInformation = async () => {
    try {
      const response = await PreviousInsuranceApi.getPayerbyId(selectedPayer);
      if (response) {
        setData((prevState) => ({
          ...prevState,
          tokenURL: response?.tokenURL || "",
        }));
        setPayerInformation(response);
       await fetchPayerConfiguration();
      } else {
        console.log("No data in response");
      }
    } catch (error) {
      console.error("Error fetching payer information:", error);
    }
  };

  const fetchPayerConfiguration = async () => {
    try {
      const response = await PreviousInsuranceApi.getPayerConfiguration(
        selectedPayer
      );
      const responseData = await response;
      const clientIdData = responseData.find(
        (item) => item.configKey === "client_id"
      );
      const clientSecretData = responseData.find(
        (item) => item.configKey === "clientSecret"
      );
      const redirectUriData = responseData.find(
        (item) => item.configKey === "redirect_uri"
      );

      // Update the state with the extracted values
      setData((prevState) => ({
        ...prevState,
        client_id: clientIdData?.configValue || "",
        clientSecret: clientSecretData?.configValue || "",
        redirect_uri: redirectUriData?.configValue || "",
      }));
    } catch (error) {
      console.error("Error fetching payer configuration:", error);
    }
  };

  const updateDatabase = async (payerMemberID, refreshToken) => {
    let accessToken;
    let userSub = "";
    accessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
    userSub = tokenPayload.sub;
    let mdata = {
      ssoUserID: userSub,
      payerID: selectedPayer,
      payerMemberID: payerMemberID, //data?.payerMemberID,
      refreshToken: refreshToken, //data?.refreshToken,
      tokenExpiry: new Date().toISOString(),
      isActive: true,
      activeDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
    };
    try {
      const response = await PreviousInsuranceApi.updateDataBaseEntry(mdata);
      navigate("/PreviousInsurer");
      return response;
    } catch (error) {
      console.error("Error while fetching memeber data==========>", error);
    }
  };

  const fetchToken = async () => {
    const { tokenURL, client_id, clientSecret } = data;
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get("code");
    const credentials = window.btoa(`${client_id}:${clientSecret}`);
    const postData = {
      redirect_uri: data.redirect_uri,
      grant_type: "authorization_code",
      code: code,
    };

    try {
      const response = await axios.post(
        tokenURL,
        new URLSearchParams(postData).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
            Authorization: `Basic ${credentials}`,
          },
        }
      );

      if (response) {
        const updatedPayerMemberID = response?.data.patient;
        const updatedRefreshToken = response?.data.refresh_token;
        await updateDatabase(updatedPayerMemberID, updatedRefreshToken);
      } else {
        navigate("/");
      }
    } catch (fetchError) {
      console.error("Error fetching the token:", fetchError);
      setIsError(fetchError?.error_description);
      setShow(!show);
      throw fetchError;
    }
  };

  useEffect(() => {
    if (data.client_id && data.clientSecret && data.tokenURL && code ) {
      fetchToken();
    }
  }, [data.client_id, data.clientSecret, data.tokenURL,code]);

  useEffect(() => {
    if (selectedPayer) {
      fetchPayerInformation();
    }
  }, [selectedPayer,code]);

  useEffect(() => {
    if (error) {
      setShow(true);
    }
  }, [error]);

  return (
    <>
      <CustomLoader />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isError === ""
            ? isError
            : "Request denied by the user. Please close the popup to move to the home page."}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => {
              setIsCheckboxChecked(false);
              handleClose();
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PayerCallBack;
