import { urls } from "../Urls";
import { Http } from "../Http";

export class PreviousInsuranceApi {
  static getPayerbyId(id: number) {
    const url = urls.previousInsurance.getPayerbyId(id);
    return Http.get(url);
  }

  static getPayerConfiguration(payerId: number) {
    const url = urls.previousInsurance.getPayerConfiguration(payerId);
    return Http.get(url);
  }

  static getPayersForDropDownData() {
    return Http.get(urls.previousInsurance.getPayersForDropDown);
  }

  static getGridData(payerName:string,SSOUserID: string,page:number,pageSize:number,sortColumn:any,sortOrder:string) {
    const url = urls.previousInsurance.getListData(payerName,SSOUserID, page, pageSize, sortColumn, sortOrder);
    return Http.get(url);
  }

  static getRefreshToken(){
    return Http.get(urls.previousInsurance.getRefreshToken)
  }
  
  static gerFhirURI(payerId:number){
    const url = urls.previousInsurance.getFhirUrl(payerId)
    return Http.get(url)
  }

  static checkEmailAlredyExist(email:any){
    const url = urls.previousInsurance.checkEmailExistence(email)
    return Http.checkEmailStatus(url)
  }

  static getBBMemberDetails(ssoguid:any){
    const url = urls.previousInsurance.getBBMember(ssoguid)
    return Http.get(url)
  }

static createMember(data:any){
  return Http.createNewUser(urls.user.createMember,data)
}

static updateDataBaseEntry(data:object){
  return Http.post(urls.previousInsurance.updateDatabse,data)
}

static updatePayerInformation(data:any){
  return Http.put(urls.previousInsurance.updatePayer,data)
}

static updtateBBMember(data:any){
  return Http.put(urls.previousInsurance.updtateBBMember,data)
}

}
